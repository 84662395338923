import { FC, useEffect, useState } from "react";
import { Button } from "@twilio-paste/core/button";
import { Stack } from "@twilio-paste/core/stack";
import { useParams, useNavigate } from "react-router-dom";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Role } from "../../constants";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Text } from "@twilio-paste/core/text";

export const SidenavLinks: FC = () => {
	const { appState } = useAppState();
	const navigate = useNavigate();
	const { agencyId } = useParams();
	const pathname = "/agencies/" + agencyId
	const [newAgency, setNewAgency] = useState<boolean>(true);

	const {
		listAgencyAgencyServiceConfig
	} = useNFCCContext();

	useEffect(() => {
		(async () => {
			try {
				// const data = await listAgencyAgencyServiceConfig(parseInt(agencyId ?? "0") || appState.agencyId);
				/* if(data.length > 0)
				{
					setNewAgency(false);
				}*/

			} catch (error) {
				console.error(error);

			}
		})();
	}, []);

	return (
		<>
			{appState.role === Role.admin && <>
				<Stack spacing="space80" orientation="vertical">
					<Text as="a" onClick={() => navigate(pathname + "/start")} variant="reset" _hover={{ color: "colorTextIconWarning" }} cursor="pointer">
						Service Setup Wizard
					</Text>
					<Text as="a" onClick={() => navigate(pathname + "/setup")} variant="reset" _hover={{ color: "colorTextIconWarning" }} cursor="pointer">
						Manage Services
					</Text>
					<Text as="a" onClick={() => navigate(pathname + "/history")} variant="reset" _hover={{ color: "colorTextIconWarning" }} cursor="pointer">
						Change History
					</Text>
					<Text as="a" onClick={() => navigate(pathname + "/holidays")} variant="reset" _hover={{ color: "colorTextIconWarning" }} cursor="pointer">
						Holidays
					</Text>
					<Text as="a" onClick={() => navigate(pathname + "/insights")} variant="reset" _hover={{ color: "colorTextIconWarning" }} cursor="pointer">
						Connection Insights
			</Text>
					<Text as="a" onClick={() => navigate(pathname + "/billing")} variant="reset" _hover={{ color: "colorTextIconWarning" }} cursor="pointer">
						Billing
					</Text>
				</Stack>
			</>}
			{appState.role === Role.client && <>
				<Stack spacing="space80" orientation="vertical">

					{newAgency && <Button onClick={() => navigate("start")} variant="reset">
						Service Setup Wizard
					</Button>}
					<Button onClick={() => navigate("setup")} variant="reset">
						Manage Services
					</Button>
					<Button onClick={() => navigate("history")} variant="reset">
						Change History
					</Button>
					<Button onClick={() => navigate("holidays")} variant="reset">
						Holidays
					</Button>
					<Button onClick={() => navigate("insights")} variant="reset">
						Connection Insights
			</Button>
					<Button onClick={() => navigate("billing")} variant="reset">
						Billing
					</Button>
				</Stack>
			</>}

		</>
	);
};
