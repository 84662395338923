import { FC, useEffect, useState } from "react";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { ChevronExpandIcon } from "@twilio-paste/icons/esm/ChevronExpandIcon";
import { MenuButton, MenuItem, Menu, useMenuState } from "@twilio-paste/menu";
import { Text } from "@twilio-paste/core/text";
import { Box } from "@twilio-paste/box";
import { Stack } from "@twilio-paste/stack";
import { LOGO, HEADERS_HEIGHT, LOGO_HEIGHT, Role, MENU_LOGO } from "../../constants";
import { LogoutModal } from "../LogoutModal/LogoutModal";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { MoreIcon } from "@twilio-paste/icons/esm/MoreIcon";
import { MediaFigure, MediaObject } from "@twilio-paste/core";
import { Separator } from "@twilio-paste/separator";

export const Header: FC = () => {
	const navigate = useNavigate();
	const userMenu = useMenuState();
	const menu = useMenuState();
	const nfccMenu = useMenuState();
	const agencyMenu = useMenuState();
	const { appState } = useAppState();
	const [agencyName, setAgencyName] = useState<string>();
	const { agencyId } = useParams();
	const pathname = "/agencies/"+agencyId

	const {
		getAgency
	}
		= useNFCCContext();

	useEffect(() => {

		(async () => {
			try {
				if (!appState.agencyName)
				{
					if(agencyId)
					{
						const ag = await getAgency(parseInt(agencyId));
						setAgencyName(ag.name ?? "");
					}
					else if(appState.role === Role.admin )
					{
						setAgencyName("NFCC Admin");
					}
				}
				else
				{
					setAgencyName(appState.agencyName)
				}


			} catch (error) {
				console.error(error);

			}
		})();
	}, [agencyId]);


	return (
		<>
			<Box
				style={{ background: "#067fa7" }}
				as="header"
				position="sticky"
				top="0"
				display="flex"
				justifyContent="flex-end"
				width="100%"
				height={HEADERS_HEIGHT}
				zIndex="zIndex90"
			>
				<Box width={"100%"} display="flex" color="colorTextBrandInverse">
					<Box
						paddingX="space60"
						paddingY="space50"
						marginTop="space30"
						display={["flex", "flex", "none"]}
					>
						<MenuButton {...menu} variant="reset" size="reset">
							<MoreIcon decorative={false} title="More options" />
						</MenuButton>
						<Menu {...menu} aria-label="Navigation">
							{appState.role === Role.admin && <>
								<MenuItem
									onClick={() => navigate(pathname+"/start")}
									{...menu}
								>
									Service Setup Wizard
								</MenuItem>
								<MenuItem
									onClick={() => navigate(pathname+"/setup")}
									{...menu}
								>
									Manage Services
								</MenuItem>
								<MenuItem
									onClick={() => navigate(pathname+"/history")}
									{...menu}
								>
									Change History
								</MenuItem>
								<MenuItem
									onClick={() => navigate(pathname+"/holidays")}
									{...menu}
								>
									Holidays
								</MenuItem>
								<MenuItem
									onClick={() => navigate(pathname+"/insights")}
									{...menu}
								>
									Connection Insights
								</MenuItem>
								<MenuItem
									onClick={() => navigate(pathname+"/billing")}
									{...menu}
								>
									Billing
								</MenuItem>
							</>}
							{appState.role === Role.client && <>
								<MenuItem
									onClick={() => navigate("start")}
									{...menu}
								>
									Service Setup Wizard
								</MenuItem>
								<MenuItem
									onClick={() => navigate("setup")}
									{...menu}
								>
									Manage Services
								</MenuItem>
								<MenuItem
									onClick={() => navigate("history")}
									{...menu}
								>
									Change History
								</MenuItem>
								<MenuItem
									onClick={() => navigate("holidays")}
									{...menu}
								>
									Holidays
								</MenuItem>
								<MenuItem
									onClick={() => navigate("insights")}
									{...menu}
								>
									Connection Insights
								</MenuItem>
								<MenuItem
									onClick={() => navigate("billing")}
									{...menu}
								>
									Billing
								</MenuItem>
							</>}

						</Menu>
					</Box>

					<Box
						width="sizeSidebar"
						display="flex"
						alignItems="center"
						justifyContent="center"
						paddingLeft="space160"
						marginRight="space60"
					>
						<img src={LOGO} alt="NFCC Logo" height={LOGO_HEIGHT} />
					</Box>

					<Box
						display="flex"
						flex="1"
						paddingX="space100"
						paddingY="space50"
						justifyContent="space-between"
					>
						<Box
							display="flex"
							justifyContent="space-between"
							flexDirection="row"
							width="100%"
							alignItems="center"
							borderLeftColor="colorBorderInverseLightest"
							borderLeftWidth="borderWidth10"
							borderLeftStyle="solid"
						>
							<Stack orientation='horizontal' spacing='space60'>
								<Box display="flex" flexDirection="column" paddingLeft="space80">
									<Text
										as="div"
										fontSize="fontSize20"
										color="colorTextBrandInverse"
										display={["none", "none", "flex"]}
									>
										Locator
									</Text>
									<Text
										as="h2"
										fontWeight="fontWeightBold"
										fontSize="fontSize60"
										color="colorTextBrandInverse"
										display={["none", "none", "flex"]}
									>
										{agencyName}
									</Text></Box>
								{appState.role === Role.admin && <MenuButton id="agencyMenu" {...agencyMenu} variant="secondary">

									<ChevronExpandIcon
										decorative={false}
										title="Agency menu icon"
									/>
								</MenuButton>}
								<Menu {...agencyMenu} aria-label="Agency menu actions">
									<MenuItem onClick={() => navigate("agencies")} {...nfccMenu}>
										All Agencies
									</MenuItem>

								</Menu>
							</Stack>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="flex-end"
								color="colorText"
								columnGap="space60"
							>
								{appState.role === Role.admin && <MenuButton title="Select admin choice" id="nfccMenu" {...nfccMenu} variant="secondary">

									<img src={MENU_LOGO} alt="NFCC Logo" height='20px' />
									<ChevronDownIcon
										decorative={false}
										title="User menu down chevron icon"
									/>
								</MenuButton>}
								<Menu {...nfccMenu} aria-label="NFCC admin menu actions">
									<MenuItem onClick={() => navigate("agencies")} {...nfccMenu}>
										Agencies
									</MenuItem>
									<MenuItem onClick={() => navigate("audits")} {...nfccMenu}>
										Audit
									</MenuItem>
									<MenuItem onClick={() => navigate("services")} {...nfccMenu}>
										Services
									</MenuItem>
									<MenuItem onClick={() => navigate("phone-numbers")} {...nfccMenu}>
										Phone Numbers
									</MenuItem>
									<Separator orientation="horizontal" verticalSpacing="space50" />
									<MenuItem onClick={() => navigate("agency-video")} {...nfccMenu}>
										Video Agencies
									</MenuItem>
									<MenuItem onClick={() => 
								{
									navigate('empty')
									setTimeout(() => {
										navigate("video-campaigns")
									});
								}
								} {...nfccMenu}>
										Web Video Campaigns
									</MenuItem>
									<MenuItem onClick={() => { //hacky way to allow us to navigate between web campaigns and booths with a refresh in between 
									navigate('empty')
									setTimeout(() => {
										navigate("booths")
									});
								}} {...nfccMenu}>
										Booths
									</MenuItem>
									<Separator orientation="horizontal" verticalSpacing="space50" />
									<MenuItem onClick={() => navigate("chatbots")} {...nfccMenu}>
										Chatbots
									</MenuItem>
								</Menu>

								<MenuButton id="userMenu" {...userMenu} variant="secondary">
									<UserIcon decorative={true} />
									{/* TODO - once endpoint available around user profile need to use {appState.email} here */}
									{appState.email}
									<ChevronDownIcon
										decorative={false}
										title="User menu down chevron icon"
									/>
								</MenuButton>

								<Menu {...userMenu} aria-label="Agency menu actions">
									{/* TODO - need to point this to user profile page taking in ID,
									also need user to be a user dynamic value */}
									<MenuItem onClick={() => navigate("profile")} {...userMenu}>
                    User Profile
									</MenuItem>

									{/* <MenuItem onClick={() => navigate(`profile/${user?.userId}`)} {...userMenu}>
                    User Profile
									</MenuItem> */}
								</Menu>

								<LogoutModal />
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
