import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import {  Services, Chatbot, Chatbots } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button, AlertDialog, Flex } from "@twilio-paste/core";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { HelpText } from "@twilio-paste/core/help-text";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Stack } from "@twilio-paste/core/stack"
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { Input } from "@twilio-paste/input";

export const ChatbotTable: FC = () => {
	const [chatbots, setChatbots] = useState<any[]>();
	const [filteredChatbots, setFilteredChatbots] = useState<any[]>();
	const [services, setServices] = useState<Services>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [serviceFilterValue, setServiceFilterValue] = useState<string>("All Services");
	const [labelFilterValue, setLabelFilterValue] = useState<string>("");

	const { listServices, listChatbots, deleteChatbot } = useNFCCContext();
	const { appState } = useAppState();
	const { toaster } = useToasterContext();

	// confirmation modal state management
	const [toDelete, setToDelete] = useState<any>();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = (phoneNumberId: number, rowIndex: number) => {
		setToDelete({ phoneNumberId, rowIndex });
		setIsOpen(true);
	};
	const handleClose = () => {
		setIsOpen(false);
	};

	const navigate = useNavigate();

	const getDate = () => {
		const today = DateTime.local();
		const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
		return todayDate;
	};

	const exportTableData = () => {
		if (chatbots) {
			
			
			const csvData = [
				[
					"Label",
					"Location",
					"Show Question 1?",
					"Use Production API?",
					"Services",
					"Channels"
				],
				...chatbots.map(
					({
						label,
						location,
						showQ1,
						useProd,
						services,
						connectionTypes
					}) => [
						JSON.stringify(label).replaceAll(",", ";"),
						JSON.stringify(location).replaceAll(",", ";"),
						showQ1,
						useProd,
						JSON.stringify(services).replaceAll(",", ";"),
						JSON.stringify(connectionTypes).replaceAll(",", ";")
					]
				)
			];
			const csv = csvData.map((row) => row.join(",")).join("\n");
			const csvBlob = new Blob([csv], { type: "text/csv" });
			const csvUrl = URL.createObjectURL(csvBlob);
			const downloadLink = document.createElement("a");
			downloadLink.href = csvUrl;
			downloadLink.download = `chatbots-${getDate()}.csv`;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const data: Chatbots = await listChatbots();
				if (data) {
					const newCs = [];
					for (const d of data) {
						let l = "";
						let s = "";

						for (const la of d.services ?? []) {
							s += la.key + ", ";
						}

						s = s.slice(0, -2);

						let s1 = ""

						for (const la of ( d as any).connectionTypes ?? []) {
							s1 += la + ", ";
						}

						s1 = s1.slice(0, -2);


						newCs.push({ ...d, ["servString"]: s, ["connectionTypeString"] : s1 });
					}

					setChatbots(newCs);
					setFilteredChatbots(newCs)

					const s = await listServices();
					setServices(s);

					setLoaded(true);
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve chatbot data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const handleDelete = async (chatbotId: number, rowIndex: number) => {
		if (chatbots) {
			try {
				await deleteChatbot(chatbotId);
				toaster.push({
					message: "Chatbot deleted successfully",
					variant: "success",
					dismissAfter: 4000
				}); 

				setChatbots(
					chatbots.filter((x) => x.chatbotId !== chatbotId)
				);

				setFilteredChatbots(
					chatbots.filter((x) => x.chatbotId !== chatbotId)
				);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Chatbot could not be deleted",
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};


	const handleCloseWithDelete = () => {
		setIsOpen(false);
		handleDelete(toDelete.phoneNumberId, toDelete.rowIndex);
		setToDelete({});
	};



	const handleFilterChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if(target.name == 'service')
		{
			setServiceFilterValue(target.value)
		}
		else
		{
			setLabelFilterValue(target.value)
		}
			
		

	};


	const applyFilters = () => {
		if (chatbots) {

			let filtered : any[] = chatbots;

			if (serviceFilterValue !== "All Services") {
				filtered = filtered.filter(
					(a) => (a.servString??"").includes(serviceFilterValue)
				)
			}
			if(labelFilterValue != "")
			{
				filtered = filtered.filter(
					(a) => (a.label.toLowerCase()??"").includes(labelFilterValue.toLowerCase())
				)
			}
			
			setFilteredChatbots(filtered)

		}

	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText="Chatbots" />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Flex>
					<Box display="flex" justifyContent="space-between" marginY="space60" alignItems="flex-end" columnGap="space60">
						
					<Button variant="primary" onClick={() => navigate("add")}>
							<PlusIcon decorative={false} title="Add new service" />
							Add New Chatbot
						</Button>
						<Button variant="secondary" onClick={exportTableData}>
							Download CSV
							<DownloadIcon decorative={false} title="Download csv icon" />
						</Button>
					</Box>
				</Flex>
			</Box>

			<Box display="flex" flexDirection="row" marginY="space60" justifyContent="space-between" alignItems="flex-end">

				<Box display="flex" flexDirection="row" columnGap="space60">

				<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
					<Label htmlFor="label" >Label</Label>
									<Input
										id="label"
										name="label"
										type="text"
										onChange={handleFilterChange}
										value={labelFilterValue}
									></Input>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
						<Label htmlFor="service">Service</Label>
						<Select id="service" name="service" onChange={handleFilterChange} value = {serviceFilterValue}>
							<Option value="All Services">All Services</Option>
							{services && loaded ? (

								services && services.map((s: any, index: number) => (

									<Option key={index} value={s.name} >{s.name}</Option>

								))) : null!}
						</Select>
					</Box>

					
					

				</Box>

				<Box display="flex" justifyContent="flex-end" height="fit-content">
					<Button variant="primary" onClick={applyFilters}>
						<FilterIcon decorative={false} title="Apply filters" />
							Apply
					</Button>
				</Box>

			</Box>

			<Table aria-label="Chatbot table" striped>
				<THead>
					<Tr>
						<Th>Actions</Th>
						<Th>Label</Th>
						<Th>Location</Th>
						<Th>Show Question 1?</Th>
						<Th>Use Production API?</Th>
						<Th>Services</Th>
						<Th>Channels</Th>
					</Tr>
				</THead>
				<TBody>
					{filteredChatbots && loaded ? (
						filteredChatbots &&
						filteredChatbots.map((x: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>
									<Box
										display="flex"
										justifyContent="flex-start"
										flexDirection="row"
										columnGap="space60"
									>
										<Stack orientation="vertical" spacing="space60">
											<Button
												variant="secondary"
												onClick={() =>
													navigate(`/chatbots/edit/${x.chatbotId}`)
												}
											>
												<EditIcon decorative={false} title="Edit Chatbot" />
											</Button>
											<Button
												variant="destructive"
												onClick={() => handleOpen(x.chatbotId, rowIndex)}
											>
												<DeleteIcon
													decorative={false}
													title="Delete Chatbot"
												/>
											</Button>
										</Stack>
									</Box>
								</Td>
								<Td>{x.label}</Td>
								<Td>{x.location}</Td>
								<Td>
									{(x.showQ1 === 0 && "No") ||
										(x.showQ1 === 1 && "Yes")}
								</Td>
								<Td>
									{(x.useProd === 0 && "No") ||
										(x.useProd === 1 && "Yes")}
								</Td>
								<Td>{x.servString}</Td>
								<Td>{x.connectionTypeString}</Td>

							</Tr>
						))
					) : (
						<TableSkeletonLoader numberOfTd={7} numberOfTr={1} />
					)}
				</TBody>
			</Table>
			<AlertDialog
				heading="Delete Chatbot"
				isOpen={isOpen}
				destructive
				onConfirm={handleCloseWithDelete}
				onConfirmLabel="Delete"
				onDismiss={handleClose}
				onDismissLabel="Cancel"
			>
				Please confirm you want to delete this chatbot.
				
			</AlertDialog>
		</Box>
	);
};
