/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useState } from "react";
import { NFCCClient, Token } from "@ciptex/nfcc";

const client = new NFCCClient();

export const useCiptexAuth = () => {
	const [token, setToken] = useState<Token | null>(null);
	const [isAuthReady, setIsAuthReady] = useState<boolean>(false);

	const signIn = useCallback(async (username: string, password: string) => {
		if(username && password)
		{
			const requestBody = { username, password };
			const token: Token = await client.authentication.authenticateUserAndCreateToken(requestBody);
			setToken(token);
			setIsAuthReady(true);
		}
		else
		{
			throw new Error("NO HOST ID???");
		}
	}, []);

	// const setNewPassword = async (hostId: string, { resetToken, newPassword }: { resetToken: string; newPassword: string}): Promise<void> => {
	// 	await client.hostauth.update(hostId, {
	// 		accountSid: ACCOUNT_SID,
	// 		resetToken,
	// 		newPassword
	// 	});
	// };

	const signOut = useCallback((): Promise<void> => {
		setToken(null);
		return Promise.resolve();
	}, []);

	return { token, isAuthReady, signIn, signOut };
}