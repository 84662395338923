/* eslint @typescript-eslint/no-var-requires: "off" */

import { Flex } from "@twilio-paste/flex";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Stack } from "@twilio-paste/stack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { PhoneNumber, Services } from "@ciptex/nfcc";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Spinner } from "@twilio-paste/core/dist/spinner";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useParams, useNavigate } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import {
	Checkbox,
	CheckboxGroup,
	Radio,
	RadioGroup,
	Separator
} from "@twilio-paste/core";
import { TextArea } from "@twilio-paste/textarea";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";

export const EditPhoneNumber: FC = () => {
	const { phoneNumberId } = useParams();
	const navigate = useNavigate();
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>();
	const [services, setServices] = useState<any[]>();
	const [loaded, setLoaded] = useState<boolean>();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _ = require("lodash");

	const { getPhoneNumber, updatePhoneNumber, listServices } = useNFCCContext();

	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setPhoneNumber({
			...phoneNumber,
			[target.name]: target.value
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				if (phoneNumber) {
					await updatePhoneNumber(
						parseInt(phoneNumberId ? phoneNumberId : ""),
						phoneNumber ? phoneNumber : {}
					);
					setLoaded(true);
					toaster.push({
						message: "Phone number details updated",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/phone-numbers");
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Phone number details could not be updated",
					variant: "success",
					dismissAfter: 4000
				});
			}
		})();
	};

	useEffect(() => {
		if (!phoneNumberId) {
			navigate("/phone-numbers");
			return;
		}
		(async () => {
			try {
				const data: PhoneNumber = await getPhoneNumber(
					parseInt(phoneNumberId ? phoneNumberId : "")
				);
				setPhoneNumber(data);

				const s: Services = await listServices();
				const sLite = (s ?? []).map(({ serviceId, name }) => {
					return { serviceId, name };
				});
				setServices(sLite);

				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const onCheckboxChangeLanguages = ({ target }: any) => {
		if (target.checked) {
			if (phoneNumber) {
				const l = (phoneNumber.languages ?? []).map((x) => x);
				l.push(target.value);
				const newPn: PhoneNumber = { ...phoneNumber, ["languages"]: l };
				setPhoneNumber(newPn);
			}
		} else {
			if (phoneNumber) {
				const l: any[] = (phoneNumber.languages ?? []).filter(
					(i) => i !== target.value
				);
				const newPn: PhoneNumber = { ...phoneNumber, ["languages"]: l };
				setPhoneNumber(newPn);
			}
		}
	};

	const onCheckboxChangeServices = ({ target }: any) => {
		if (target.checked) {
			if (phoneNumber) {
				const s: any[] = (phoneNumber.services ?? []).map((x) => x);
				s.push((services ?? []).filter((i) => i.name === target.value)[0]);
				const newPn: PhoneNumber = { ...phoneNumber, ["services"]: s };
				setPhoneNumber(newPn);
			}
		} else {
			if (phoneNumber) {
				const l: any[] = (phoneNumber.services ?? []).filter(
					(i) => i.name !== target.value
				);
				const newPn: PhoneNumber = { ...phoneNumber, ["services"]: l };
				setPhoneNumber(newPn);
			}
		}
	};

	const handleTextChange = (e: any) => {
		setPhoneNumber({
			...phoneNumber,
			[e.target.name]: e.target.value
		});
	};

	const formatTollFree = (v: number) => {
		if (v === 0) {
			return "Yes";
		} else {
			return "No";
		}
	};

	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
		>
			<Box>
				<HeaderTitleText titleText="Edit Phone Number" />
				{phoneNumber && loaded ? (
					<form onSubmit={(e) => handleSubmit(e)}>
						<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="name">Number</Label>
									<Input
										disabled
										id="name"
										name="name"
										type="text"
										onChange={handleChange}
										defaultValue={phoneNumber.number}
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<RadioGroup
										required
										name="isTollFree"
										value={formatTollFree(phoneNumber.isTollFree ?? 1)}
										legend="Skips IVR"
										// onChange={handleChange}
										onChange={(e) => {
											setPhoneNumber({
												...phoneNumber,
												["isTollFree"]: e === "Yes" ? 0 : 1
											});
										}}
									>
										<Radio id="yes" value="Yes">
											Yes
										</Radio>
										<Radio id="no" value="No">
											No
										</Radio>
									</RadioGroup>
									
								</Box>
							</Flex>
						</Box>
						<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="campaignName">Campaign</Label>
									<Input
										id="campaignName"
										name="campaignName"
										type="text"
										onChange={handleChange}
										defaultValue={phoneNumber.campaignName}
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="description">Description</Label>
									<TextArea
										onChange={(e) => {
											handleTextChange(e);
										}}
										placeholder="Add text"
										id="description"
										name="description"
										defaultValue={phoneNumber.description}
									/>
								</Box>
							</Flex>
						</Box>
						<Separator orientation="horizontal" verticalSpacing="space50" />
						<SubtitleText subtitleText="Configuration"></SubtitleText>
						<Box paddingRight="space60">
							<Flex>
								
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<RadioGroup
										required
										name="errorHandlingType"
										value={phoneNumber.errorHandlingType}
										legend="Error Handling Type"
										onChange={(newValue) => {
											setPhoneNumber({
												...phoneNumber,
												["errorHandlingType"]: newValue
											});
										}}
									>
										<Radio id="def" value="default" name="1">
											Default
										</Radio>
										<Radio id="ab" value="abort" name="2">
											Abort
										</Radio>
									</RadioGroup>
								</Box>
							</Flex>
						</Box>

						<Box paddingRight="space60" paddingTop="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="servs">Services</Label>
									<CheckboxGroup name="servscbgroup" legend="">
										{loaded ? (
											<CheckboxGroup name="avAgencies" legend="" helpText="">
												{services &&
													services.map((service: any, index: number) => (
														<Checkbox
															key={service.serviceId}
															id={service.name}
															checked={(phoneNumber.services ?? [])
																.map(({ name }) => name)
																.includes(service.name)}
															value={service.name}
															name={service.name}
															onClick={(e) => onCheckboxChangeServices(e)}
														>
															{service.name}
														</Checkbox>
													))}
											</CheckboxGroup>
										) : (
											null!
										)}
									</CheckboxGroup>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="avLangs">Languages</Label>
									<CheckboxGroup name="langscbgroup" legend="">
										<CheckboxGroup name="avLangs" legend="" helpText="">
											<Checkbox
												key="English"
												id="English"
												checked={(phoneNumber.languages ?? []).includes(
													"English"
												)}
												value="English"
												name="English"
												onClick={(e) => onCheckboxChangeLanguages(e)}
											>
												English
											</Checkbox>
											<Checkbox
												key="Spanish"
												id="Spanish"
												checked={(phoneNumber.languages ?? []).includes(
													"Spanish"
												)}
												value="Spanish"
												name="Spanish"
												onClick={(e) => onCheckboxChangeLanguages(e)}
											>
												Spanish
											</Checkbox>
										</CheckboxGroup>
									</CheckboxGroup>
								</Box>
							</Flex>
						</Box>

						<Stack orientation="horizontal" spacing="space200">
							<Button variant="secondary" onClick={() => navigate(-1)}>
								Cancel
							</Button>
							<Button type="submit" variant="primary">
								Save
							</Button>
						</Stack>
					</form>
				) : (
					<Flex vAlignContent="center" hAlignContent="center">
						<Spinner size="sizeIcon110" decorative={false} title="Loading" />
					</Flex>
				)}
			</Box>
		</Box>
	);
};
