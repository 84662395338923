/* eslint @typescript-eslint/no-var-requires: "off" */

import { Flex } from "@twilio-paste/flex";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Stack } from "@twilio-paste/stack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { AgencyVideo, Agencies, AgencyVideos, AgencyVideoNew } from "@ciptex/nfcc";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Spinner } from "@twilio-paste/core/dist/spinner";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import { HelpText } from "@twilio-paste/core/help-text";
import { TextArea } from "@twilio-paste/textarea";
import { Select } from "@twilio-paste/core/select";
import {
	Option
} from "@twilio-paste/core";

// importing a stylesheet so can style the color picker input,
// for some reason could not get styled components to work
import "./EditAgencyVideo.css";

export const EditAgencyVideo: FC = () => {
	const location = useLocation();
	const { agencyId2 } = useParams();
	const agencyId = agencyId2;
	const navigate = useNavigate();
	const [agencyVideo, setAgencyVideo] = useState<AgencyVideo>();
	const [loaded, setLoaded] = useState<boolean>();
	const [agencies, setAgencies] = useState<Agencies>();
	const [sidsEditable, setSidsEditable] = useState<boolean>(false);
	const [hasAgencyError, setHasAgencyError] = useState<boolean>(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _ = require("lodash");

	const { getAgencyVideo, listAgencies, listAgencyVideo
		, updateAgencyVideo, createAgencyVideo
	} = useNFCCContext();

	const { toaster } = useToasterContext();

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setAgencyVideo({
			...agencyVideo,
			[target.name]: target.value
		});
	};

	const handleChangeStrings = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		console.log("setting strings", target.name, target.value)
		const strings = { ...agencyVideo?.strings }
		strings[target.name] = target.value
		console.log(strings)
		setAgencyVideo({
			...agencyVideo,
			strings
		});
	};

	const handleChangeBranding = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		console.log("setting branding", target.name, target.value)
		const branding = { ...agencyVideo?.branding }
		branding[target.name] = target.value
		console.log(branding)
		setAgencyVideo({
			...agencyVideo,
			branding,
			[target.name]: target.value
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		(async () => {
			try {
				if (agencyVideo) {

					console.log("CAZ", agencyVideo)

					if (!agencyVideo.agencyId || (agencyVideo.agencyId as unknown as string) === "") {
						setHasAgencyError(true)
						return
					}
					else { setHasAgencyError(false) }

					// set the branding fields if they're blank
					const { branding } = agencyVideo
					if (!Object.keys(branding).includes("backgroundColor")) {
						branding.backgroundColor = "#0085AD"
					}

					if (!Object.keys(branding).includes("primaryButtonColor")) {
						branding.primaryButtonColor = "#032E4D"
					}

					if (!Object.keys(branding).includes("secondaryButtonColor")) {
						branding.secondaryButtonColor = "#00C1E1"
					}

					if (!Object.keys(branding).includes("destructiveButtonColor")) {
						branding.destructiveButtonColor = "#DB3300"
					}

					if (!Object.keys(branding).includes("waitUrl")) {
						branding.waitUrl = "https://cdn.ciptex.com/race-moh/intense_moh.mp3"
					}

					if (!Object.keys(branding).includes("logoUrl")) {
						branding.logoUrl = "https://cdn.ciptex.com/nfcc/nfcc-white.png"
					}


					const av = { ...agencyVideo, branding }

					console.log(av)

					if (agencyId) {
						// it's an update
						await updateAgencyVideo(
							parseInt(agencyId ? agencyId : ""),
							av
						);
					}
					else {
						// it's a create
						await createAgencyVideo(av as AgencyVideoNew)
					}



					setLoaded(true);
					toaster.push({
						message: "Agency video configuration updated",
						variant: "success",
						dismissAfter: 4000
					});
					navigate("/agency-video");
				}
			} catch (error) {
				console.error(error);
				if(agencyId)
				{
					toaster.push({
						message: "Agency video configuration could not be updated",
						variant: "error",
						dismissAfter: 4000
					});
				}
				else
				{
					toaster.push({
						message: "Agency video configuration could not be created",
						variant: "error",
						dismissAfter: 4000
					});
				}
				
			}
		})();
	};

	useEffect(() => {
		if (!agencyId && location.pathname.split("/").pop() !== "add") {
			navigate("/agency-video");
			return;
		}
		(async () => {
			try {
				if (agencyId) {
					const data: AgencyVideo = await getAgencyVideo(
						parseInt(agencyId ? agencyId : "")
					);
					setAgencyVideo(data);
					if (!data.accountSid || !data.kioskSid) {
						setSidsEditable(true)
					}
				}
				else {
					setSidsEditable(true)
					setAgencyVideo({})
				}


				const ags: Agencies = await listAgencies();

				const avs: AgencyVideos = await listAgencyVideo();

				// filter out the agencies who already have config set up
				const filteredAV = avs.filter(
					(a) => (a.status ?? "") == "Not enabled"
				)

				const result = filteredAV.map(a => a.agencyId);

				const filteredAg = ags.filter(
					(a) => result.includes(a.agencyId ?? 0) || a.agencyId == parseInt(agencyId ?? "0")
				)


				setAgencies(filteredAg)



				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);


	const handleTextChange = (e: any) => {
		const strings = { ...agencyVideo?.strings }
		strings[e.target.name] = e.target.value
		setAgencyVideo({
			...agencyVideo,
			strings
		});
	};



	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
		>
			<Box>
				<HeaderTitleText titleText={(agencyId && "Edit " || "Add New ")+ "Agency Video Configuration"} />
				{agencyVideo && loaded ? (
					<form onSubmit={(e) => handleSubmit(e)}>
						<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="category">
										Agency
									</Label>
									<Select
										onChange={handleChange}
										name="agencyId"
										id="agencyId"
										defaultValue={agencyId}
									>
										<Option defaultValue="Choose an option" value={""}>
											Select an Agency
										</Option>
										{agencies && loaded
											? agencies &&
											agencies.map((ag: any, index: number) => (
												<Option key={index} value={ag.agencyId}>
													{ag.netsuiteRef + ": " + ag.name}</Option>
											))
											: null!}
									</Select>
									{hasAgencyError && (
										<HelpText variant="error">
											Cannot submit without an Agency
										</HelpText>
									)}

								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Name</Label>
									<Input
										id="name"
										name="name"
										type="text"
										onChange={handleChange}
										value={agencyVideo.name}
										required
									></Input>
								</Box>

							</Flex>
						</Box>
						<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Account SID</Label>
									<Input
										disabled={!sidsEditable}
										id="accountSid"
										name="accountSid"
										type="text"
										onChange={handleChange}
										value={agencyVideo.accountSid}
										required
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Kiosk SID</Label>
									<Input
										disabled={!sidsEditable}
										id="kioskSid"
										name="kioskSid"
										type="text"
										onChange={handleChange}
										value={agencyVideo.kioskSid}
										required
									></Input>
								</Box>
								{/*<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Web Kiosk Title</Label>
									<Input
										id="title"
										name="title"
										type="text"
										onChange={handleChangeStrings}
										value={(agencyVideo as any).strings?.title}
										required
									></Input>
								</Box>*/}

							</Flex>
						</Box>
						{!agencyId && <Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">API key</Label>
									<Input
										disabled={!sidsEditable}
										id="apiKey"
										name="apiKey"
										type="text"
										onChange={handleChange}
										value={agencyVideo.apiKey}
										required
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">API key SID</Label>
									<Input
										disabled={!sidsEditable}
										id="apiKeySid"
										name="apiKeySid"
										type="text"
										onChange={handleChange}
										value={agencyVideo.apiKeySid}
										required
									></Input>
								</Box>
								{/*<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Web Kiosk Title</Label>
									<Input
										id="title"
										name="title"
										type="text"
										onChange={handleChangeStrings}
										value={(agencyVideo as any).strings?.title}
										required
									></Input>
								</Box>*/}

							</Flex>
						</Box>}
						{/*<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Background Color</Label>
									<input
										id="backgroundColor"
										name="backgroundColor"
										type="color"
										className="colorPicker"
										placeholder={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.backgroundColor || "#0085AD"}
										onChange={handleChangeBranding}
										value={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.backgroundColor || "#0085AD"}
									></input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Primary Button Color</Label>
									<input
										id="primaryButtonColor"
										name="primaryButtonColor"
										type="color"
										className="colorPicker"
										onChange={handleChangeBranding}
										value={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.primaryButtonColor || "#032E4D"}
										placeholder={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.primaryButtonColor || "#032E4D"}
									></input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Secondary Button Color</Label>
									<input
										id="secondaryButtonColor"
										name="secondaryButtonColor"
										type="color"
										className="colorPicker"
										onChange={handleChangeBranding}
										value={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.secondaryButtonColor || "#00C1E1"}
										placeholder={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.secondaryButtonColor || "#00C1E1"}
									></input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Destructive Button Color</Label>
									<input
										id="destructiveButtonColor"
										name="destructiveButtonColor"
										type="color"
										className="colorPicker"
										onChange={handleChangeBranding}
										value={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.destructiveButtonColor || "#DB3300"}
										placeholder={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.destructiveButtonColor || "#DB3300"}

									></input>
								</Box>

							</Flex>
							</Box>*/}
						<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Logo URL</Label>
									<Input
										id="logoUrl"
										name="logoUrl"
										type="text"
										onChange={handleChangeBranding}
										value={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.logoUrl || "https://cdn.ciptex.com/nfcc/nfcc-white.png"}
										// defaultValue={agencyVideo.accountSid}
										required
									></Input>
								</Box>
								{/*<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Wait Audio URL</Label>
									<Input
										id="waitUrl"
										name="waitUrl"
										type="text"
										onChange={handleChangeBranding}
										// defaultValue={agencyVideo.kioskSid}
										value={Object.keys(agencyVideo as any).includes("branding") && (agencyVideo as any).branding?.waitUrl || "https://cdn.ciptex.com/race-moh/intense_moh.mp3"}
										required
									></Input>
						</Box>*/}
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="name">Virtual Background URL</Label>
									<Input
										id="workerBackgroundUrl"
										name="workerBackgroundUrl"
										type="text"
										onChange={handleChangeBranding}
										value={(agencyVideo as any).branding?.workerBackgroundUrl}
									// defaultValue={agencyVideo.kioskSid}
									></Input>
								</Box>

							</Flex>
						</Box>
						{/*<Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="description">Closed message</Label>
									<TextArea
										onChange={(e) => {
											handleTextChange(e);
										}}
										placeholder="Add text"
										id="closedMessage"
										name="closedMessage"
										required
										value={(agencyVideo as any).strings?.closedMessage}
									/>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="description">Wait Message</Label>
									<TextArea
										onChange={(e) => {
											handleTextChange(e);
										}}
										placeholder="Add text"
										id="waitMessage"
										name="waitMessage"
										required
										value={(agencyVideo as any).strings?.waitMessage}
									/>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="description">Privacy Policy Statement</Label>
									<TextArea
										onChange={(e) => {
											handleTextChange(e);
										}}
										placeholder="Add text"
										id="privacyLink"
										name="privacyLink"
										required
										value={(agencyVideo as any).strings?.privacyLink}
									/>
								</Box>



							</Flex>
									</Box>*/}



						<Stack orientation="horizontal" spacing="space200">
							<Button variant="secondary" onClick={() => navigate(-1)}>
								Cancel
							</Button>
							<Button type="submit" variant="primary">
								Save
							</Button>
						</Stack>
					</form>
				) : (
					<Flex vAlignContent="center" hAlignContent="center">
						<Spinner size="sizeIcon110" decorative={false} title="Loading" />
					</Flex>
				)}
			</Box>
		</Box>
	);
};
