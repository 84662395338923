/* eslint @typescript-eslint/no-var-requires: "off" */

import { Flex } from "@twilio-paste/flex";
import { Label } from "@twilio-paste/label";
import { Input } from "@twilio-paste/input";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Stack } from "@twilio-paste/stack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { AgencyVideo, Agencies, VideoCampaignNew, VideoCampaign, Services } from "@ciptex/nfcc";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Spinner } from "@twilio-paste/core/dist/spinner";
import { FORM_BACKGROUND } from "../../constants";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { SubtitleText } from "../HeaderTitleText/SubtitleText";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useToasterContext } from "../../hooks/useToasterContext";
import { HelpText } from "@twilio-paste/core/help-text";
import { TextArea } from "@twilio-paste/textarea";
import { Select } from "@twilio-paste/core/select";

import styled from "styled-components";

import {
	Checkbox,
	CheckboxGroup,
	Radio,
	RadioGroup,
	Separator,
	Option
} from "@twilio-paste/core";

// importing a stylesheet so can style the color picker input,
// for some reason could not get styled components to work
import "./EditVideoCampaign.css"

export const EditVideoCampaign: FC = () => {
	const location = useLocation();
	const { videoCampaignId } = useParams();

	const navigate = useNavigate();
	const [videoCampaign, setVideoCampaign] = useState<VideoCampaign>();
	const [loaded, setLoaded] = useState<boolean>();
	const [agencies, setAgencies] = useState<Agencies>();
	const [services, setServices] = useState<Services>();

	const [hasStateError, setHasStateError] = useState<boolean>(false);
	const [languagesError, setLanguagesError] = useState<string>("");
	const [servicesError, setServicesError] = useState<string>("");
	const [agenciesError, setAgenciesError] = useState<string>("");

	const [isBooths, setIsBooths] = useState<boolean>(false);

	const states = [
		{
			"name": "Alabama",
			"abbreviation": "AL"
		},
		{
			"name": "Alaska",
			"abbreviation": "AK"
		},
		{
			"name": "American Samoa",
			"abbreviation": "AS"
		},
		{
			"name": "Arizona",
			"abbreviation": "AZ"
		},
		{
			"name": "Arkansas",
			"abbreviation": "AR"
		},
		{
			"name": "California",
			"abbreviation": "CA"
		},
		{
			"name": "Colorado",
			"abbreviation": "CO"
		},
		{
			"name": "Connecticut",
			"abbreviation": "CT"
		},
		{
			"name": "Delaware",
			"abbreviation": "DE"
		},
		{
			"name": "District Of Columbia",
			"abbreviation": "DC"
		},
		{
			"name": "Federated States Of Micronesia",
			"abbreviation": "FM"
		},
		{
			"name": "Florida",
			"abbreviation": "FL"
		},
		{
			"name": "Georgia",
			"abbreviation": "GA"
		},
		{
			"name": "Guam",
			"abbreviation": "GU"
		},
		{
			"name": "Hawaii",
			"abbreviation": "HI"
		},
		{
			"name": "Idaho",
			"abbreviation": "ID"
		},
		{
			"name": "Illinois",
			"abbreviation": "IL"
		},
		{
			"name": "Indiana",
			"abbreviation": "IN"
		},
		{
			"name": "Iowa",
			"abbreviation": "IA"
		},
		{
			"name": "Kansas",
			"abbreviation": "KS"
		},
		{
			"name": "Kentucky",
			"abbreviation": "KY"
		},
		{
			"name": "Louisiana",
			"abbreviation": "LA"
		},
		{
			"name": "Maine",
			"abbreviation": "ME"
		},
		{
			"name": "Marshall Islands",
			"abbreviation": "MH"
		},
		{
			"name": "Maryland",
			"abbreviation": "MD"
		},
		{
			"name": "Massachusetts",
			"abbreviation": "MA"
		},
		{
			"name": "Michigan",
			"abbreviation": "MI"
		},
		{
			"name": "Minnesota",
			"abbreviation": "MN"
		},
		{
			"name": "Mississippi",
			"abbreviation": "MS"
		},
		{
			"name": "Missouri",
			"abbreviation": "MO"
		},
		{
			"name": "Montana",
			"abbreviation": "MT"
		},
		{
			"name": "Nebraska",
			"abbreviation": "NE"
		},
		{
			"name": "Nevada",
			"abbreviation": "NV"
		},
		{
			"name": "New Hampshire",
			"abbreviation": "NH"
		},
		{
			"name": "New Jersey",
			"abbreviation": "NJ"
		},
		{
			"name": "New Mexico",
			"abbreviation": "NM"
		},
		{
			"name": "New York",
			"abbreviation": "NY"
		},
		{
			"name": "North Carolina",
			"abbreviation": "NC"
		},
		{
			"name": "North Dakota",
			"abbreviation": "ND"
		},
		{
			"name": "Northern Mariana Islands",
			"abbreviation": "MP"
		},
		{
			"name": "Ohio",
			"abbreviation": "OH"
		},
		{
			"name": "Oklahoma",
			"abbreviation": "OK"
		},
		{
			"name": "Oregon",
			"abbreviation": "OR"
		},
		{
			"name": "Palau",
			"abbreviation": "PW"
		},
		{
			"name": "Pennsylvania",
			"abbreviation": "PA"
		},
		{
			"name": "Puerto Rico",
			"abbreviation": "PR"
		},
		{
			"name": "Rhode Island",
			"abbreviation": "RI"
		},
		{
			"name": "South Carolina",
			"abbreviation": "SC"
		},
		{
			"name": "South Dakota",
			"abbreviation": "SD"
		},
		{
			"name": "Tennessee",
			"abbreviation": "TN"
		},
		{
			"name": "Texas",
			"abbreviation": "TX"
		},
		{
			"name": "Utah",
			"abbreviation": "UT"
		},
		{
			"name": "Vermont",
			"abbreviation": "VT"
		},
		{
			"name": "Virgin Islands",
			"abbreviation": "VI"
		},
		{
			"name": "Virginia",
			"abbreviation": "VA"
		},
		{
			"name": "Washington",
			"abbreviation": "WA"
		},
		{
			"name": "West Virginia",
			"abbreviation": "WV"
		},
		{
			"name": "Wisconsin",
			"abbreviation": "WI"
		},
		{
			"name": "Wyoming",
			"abbreviation": "WY"
		}
	]
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const _ = require("lodash");

	const { listServices, listAgencies
		, getVideoCampaign
		, createVideoCampaign, updateVideoCampaign
	} = useNFCCContext();

	const { toaster } = useToasterContext();

	const ColourInput = styled("input")`
	box-sizing: border-box;
	display: flex;
	width: 100%;
	background-color: rgb(255, 255, 255);
	box-shadow: rgb(136, 145, 170) 0px 0px 0px 1px;
	border-radius: 4px;
	transition: box-shadow 100ms ease-in 0s;
	cursor: pointer;
	padding: 0px 8px;
	height: 36px;
}
`;

	const handleChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setVideoCampaign({
			...videoCampaign,
			[target.name]: target.value
		});
	};

	const onCheckboxChangeLanguages = ({ target }: any) => {
		if (target.checked) {
			if (videoCampaign) {
				const l = (videoCampaign.availableLanguages ?? []).map((x) => x);
				l.push(target.value);
				const n: VideoCampaign = { ...videoCampaign, ["availableLanguages"]: l };
				setVideoCampaign(n);
			}
		} else {
			if (videoCampaign) {
				const l: any[] = (videoCampaign.availableLanguages ?? []).filter(
					(i) => i !== target.value
				);
				const n: VideoCampaign = { ...videoCampaign, ["availableLanguages"]: l };
				setVideoCampaign(n);
			}
		}
	};


	const onCheckboxChangeServices = ({ target }: any) => {
		if (target.checked) {
			if (videoCampaign) {
				const s: any[] = (videoCampaign.availableServices ?? []).map((x) => x);
				s.push(target.value);
				const n: VideoCampaign = { ...videoCampaign, ["availableServices"]: s };
				setVideoCampaign(n);

			}
		} else {
			if (videoCampaign) {
				const s: any[] = (videoCampaign.availableServices ?? []).filter(
					(i) => i !== target.value
				);
				const n: VideoCampaign = { ...videoCampaign, ["availableServices"]: s };
				setVideoCampaign(n);
			}
		}
	};



	const handleSubmit = async (e: any) => {
		// e.preventDefault();
		(async () => {
			try {
				console.log("here??")
				if (videoCampaign) {

					// set the branding fields if they're blank
					const v = { ...videoCampaign }
					if (!Object.keys(v).includes("backgroundColor")) {
						v.backgroundColor = "#0085AD"
					}

					if (!Object.keys(v).includes("buttonColor")) {
						v.buttonColor = "#032E4D"
					}

					if (!Object.keys(v).includes("textColor")) {
						v.textColor = "#FFFFFF"
					}

					if (!Object.keys(v).includes("linkColor")) {
						v.linkColor = "#64D3E4"
					}


					if (!Object.keys(v).includes("logoUrl")) {
						v.logoUrl = "https://cdn.ciptex.com/nfcc/nfcc-white.png"
					}

					if (!Object.keys(v).includes("isCodeEnabled")) {
						v.isCodeEnabled = 0
					}

					let msg = ""

					// validation
					let isError = false;
					setServicesError("")
					setAgenciesError("")
					setLanguagesError("")

					if (!Object.keys(v).includes("availableServices") || v.availableServices?.length === 0 || v.availableServices == null) {
						isError = true
						setServicesError("Must select at least one service")
					}

					if (!Object.keys(v).includes("availableAgencies") || v.availableAgencies?.length === 0 || v.availableAgencies == null) {
						isError = true
						setAgenciesError("Must select at least one agency")
					}


					if (!Object.keys(v).includes("availableLanguages") || v.availableLanguages?.length === 0 || v.availableLanguages == null) {
						isError = true
						setLanguagesError("Must select at least one language")
					}

					console.log("isError", isError)


					if(!isError)
					{
						if (videoCampaignId) {
							console.log("here", v)
							// it's an update
							await updateVideoCampaign(
								parseInt(videoCampaignId ? videoCampaignId : ""),
								v
							);
							msg = " configuration updated"
						}
						else {
							// it's a create
							if (isBooths) {
								v.type = "booth"
							}
							else {
								v.type = "web"
							}

							await createVideoCampaign(v as VideoCampaignNew)
							msg = " created successfully"
						}


						if (isBooths) {
							msg = "Booth" + msg
						}
						else {
							msg = "Video campaign" + msg
						}

						setLoaded(true);
						toaster.push({
							message: msg,
							variant: "success",
							dismissAfter: 4000
						});
						if (isBooths) {
							navigate("/booths");
						}
						else {
							navigate("/video-campaigns");
						}
					}


				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Video configuration could not be updated",
					variant: "success",
					dismissAfter: 4000
				});
			}
		})();
	};


	function allChecked(): boolean {
		try {
			if (videoCampaign && agencies) {
				return _.isEmpty(
					_.xor(
						(videoCampaign.availableAgencies ?? []).map(({ name }) => name),
						(agencies ?? []).map(({ name }) => name)
					)
				);
			} else {
				return false;
			}
		} catch {
			return false;
		}
	}


	const onCheckboxChangeAgencies = ({ target }: any) => {
		if (target.checked) {
			if (videoCampaign) {
				const avAgencies: any[] = [];
				for (const a of videoCampaign.availableAgencies ?? []) {
					avAgencies.push(a);
				}
				avAgencies.push({ name: target.value, agencyId: target.id });
				const a: any[] = (avAgencies ?? []).filter((i) => i.name !== null);
				const n: VideoCampaign = { ...videoCampaign, ["availableAgencies"]: a };
				setVideoCampaign(n);
			}
		} else {
			if (videoCampaign) {
				const avAgencies: any[] = (videoCampaign.availableAgencies ?? []).filter(
					(i) => i.name !== target.value
				);
				const n: VideoCampaign = {
					...videoCampaign,
					["availableAgencies"]: avAgencies
				};
				setVideoCampaign(n);
			}
		}
	};


	const setAllChecked = (checked: boolean) => {
		if (checked) {
			if (videoCampaign && agencies) {
				const avAgencies: any[] = agencies ?? []

				const a: any[] = [];
				for (const ag of avAgencies) {
					a.push({ name: ag.name, agencyId: ag.agencyId });
				}

				const n: VideoCampaign = { ...videoCampaign, ["availableAgencies"]: a };
				setVideoCampaign(n);
			}
		} else {
			if (videoCampaign) {
				const n: VideoCampaign = { ...videoCampaign, ["availableAgencies"]: [] };
				setVideoCampaign(videoCampaign);
			}
		}
	};


	useEffect(() => {
		let isBooth = false;
		if (location.pathname.includes("booth")) {
			setIsBooths(true);
			isBooth = true;
		}

		if (!videoCampaignId && location.pathname.split("/").pop() !== "add") {
			if (isBooth) {
				navigate("/booths");
			}
			else {
				navigate("/video-campaigns");
			}

			return;
		}
		(async () => {
			try {

				if (videoCampaignId) {
					const data: AgencyVideo = await getVideoCampaign(
						parseInt(videoCampaignId ? videoCampaignId : "")
					);
					setVideoCampaign(data);
					console.log("vc", data)
				}
				else {
					setVideoCampaign({})
				}


				const ags: Agencies = await listAgencies();
				const s: Services = await listServices();

				setAgencies(ags)
				setServices(s)

				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);


	const handleTextChange = (e: any) => {


		setVideoCampaign({
			...videoCampaign,
			[e.target.name]: e.target.value
		});
	};



	return (
		<Box
			display="flex"
			flexWrap="wrap"
			flexDirection="column"
			style={{ background: FORM_BACKGROUND }}
			padding="space60"
			borderRadius="borderRadius30"
			marginBottom="space60"
			marginTop="space40"
			borderStyle="solid"
			borderWidth="borderWidth10"
			borderColor="colorBorder"
		>
			<Box>
				<HeaderTitleText titleText={isBooths && "Edit Booth Configuration" || "Edit Web Video Configuration"} />
				{videoCampaign && loaded ? (
					<>
						<Box paddingRight="space60">
							<SubtitleText subtitleText="Details" />
							<Box display="flex" flexDirection="row" columnGap="space200">
								<Box paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Name</Label>
									<Input
										id="name"
										name="name"
										type="text"
										onChange={handleChange}
										value={videoCampaign.name}
										required
									></Input>
								</Box>



								{videoCampaignId && <Box paddingBottom="space60" paddingRight="space60" width="35%">
									<Label required htmlFor="name">URL</Label>
									<Input
										id="url"
										name="url"
										type="text"
										onChange={handleChange}
										value={videoCampaign.url}
										disabled={true}
										required
									></Input>
								</Box>}

							</Box>
						</Box>
						{isBooths && <Box paddingRight="space60">
							<Flex>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="name">Location</Label>
									<Input
										id="location"
										name="location"
										type="text"
										onChange={handleChange}
										value={videoCampaign.location}
										required
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="state">
										State
									</Label>
									<Select
										onChange={handleChange}
										name="state"
										id="state"
										defaultValue={videoCampaign.state}
										required
									>
										<Option defaultValue="Choose an option" value={""}>
											Select a State
										</Option>
										{states && loaded
											? states &&
											states.map((s: any, index: number) => (
												<Option key={index} value={s.abbreviation}>
													{s.name}</Option>
											))
											: null!}
									</Select>
									{hasStateError && (
										<HelpText variant="error">
											Cannot submit without a state
										</HelpText>
									)}

								</Box>

							</Flex>
						</Box>}

						<Separator orientation="horizontal" verticalSpacing="space50" />

						{isBooths && <Box paddingRight="space60">
							<SubtitleText subtitleText="Location Contact" />
							<Flex>

								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="locationContactName">Name</Label>
									<Input
										id="locationContactName"
										name="locationContactName"
										type="text"
										onChange={handleChange}
										value={videoCampaign.locationContactName}
										required
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="locationContactPhone">Phone</Label>
									<Input
										id="locationContactPhone"
										name="locationContactPhone"
										type="text"
										onChange={handleChange}
										value={videoCampaign.locationContactPhone}
										required
									></Input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="locationContactEmail">Email</Label>
									<Input
										id="locationContactEmail"
										name="locationContactEmail"
										type="text"
										onChange={handleChange}
										value={videoCampaign.locationContactEmail}
										required
									></Input>
								</Box>


							</Flex>
						</Box>}

						<Box paddingRight="space60">
							<SubtitleText subtitleText="Configuration" />
							<Flex>

								{isBooths && <Box width="10%" paddingBottom="space60" paddingRight="space60">
									<RadioGroup
										required
										name="codeEnabled"
										value={videoCampaign.isCodeEnabled == 1 && "Yes" || "No"}
										legend="Code Enabled?"
										// onChange={handleChange}
										onChange={(e) => {
											if (e === "Yes") {
												const v = {
													...videoCampaign,
													["isCodeEnabled"]: 1
												}
												setVideoCampaign(v);
											}
											else {
												const v = {
													...videoCampaign,
													["isCodeEnabled"]: 0
												}
												setVideoCampaign(v);
											}

										}}
									>
										<Radio id="yes" value="Yes">
											Yes
										</Radio>
										<Radio id="no" value="No">
											No
										</Radio>
									</RadioGroup>

								</Box>}

								<Box width="10%" paddingBottom="space60" paddingRight="space60">
									<Label htmlFor="avLangs" required>Languages</Label>
									<CheckboxGroup name="langscbgroup" legend="">
										<CheckboxGroup name="avLangs" legend="" helpText="">
											<Checkbox
												key="English"
												id="English"
												checked={(videoCampaign.availableLanguages ?? []).includes(
													"English"
												)}
												value="English"
												name="English"
												onClick={(e) => onCheckboxChangeLanguages(e)}
											>
												English
											</Checkbox>
											<Checkbox
												key="Spanish"
												id="Spanish"
												checked={(videoCampaign.availableLanguages ?? []).includes(
													"Spanish"
												)}
												value="Spanish"
												name="Spanish"
												onClick={(e) => onCheckboxChangeLanguages(e)}
											>
												Spanish
											</Checkbox>
										</CheckboxGroup>
									</CheckboxGroup>
									{languagesError.length > 0 && (
										<HelpText variant="error">
											{languagesError}
										</HelpText>
									)}
								</Box>

								<Box width="70%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="servscbgroup">Services</Label>
									<CheckboxGroup name="servscbgroup" legend="">
										{loaded ? (
											<CheckboxGroup name="servs" legend="" helpText="" orientation="horizontal">
												{services &&
													services.map((service: any, index: number) => (
														<Checkbox
															key={service.serviceId}
															id={service.name}
															checked={(videoCampaign.availableServices ?? [])

																.includes(service.name)}
															value={service.name}
															name={service.name}
															onClick={(e) => onCheckboxChangeServices(e)}
														>
															<Box width="300px">{service.name}</Box>
														</Checkbox>
													))}
											</CheckboxGroup>
										) : (
											null!
										)}
									</CheckboxGroup>
									{servicesError.length > 0 && (
										<HelpText variant="error">
											{servicesError}
										</HelpText>
									)}
								</Box>

								<Box width="15%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="avAgencies">Agencies</Label>
									<CheckboxGroup name="agenciescbgroup" legend="">
										{loaded ? (
											<CheckboxGroup name="avAgencies" legend="" helpText="">
												<Checkbox
													id="select_all"
													checked={allChecked()}
													indeterminate={
														!allChecked() &&
														(videoCampaign.availableAgencies ?? []).length > 1
													}
													onChange={(e) => setAllChecked(e.target.checked)}
												>
													Select all
												</Checkbox>
												{agencies &&
													agencies.map((agency: any, index: number) => (
														<Checkbox
															key={agency.agencyId}
															id={agency.agencyId}
															checked={(videoCampaign.availableAgencies ?? [])
																.map(({ name }) => name)
																.includes(agency.name)}
															value={agency.name}
															name={agency.name}
															onClick={(e) => onCheckboxChangeAgencies(e)}
														>
															{agency.netsuiteRef + "  -  " + agency.name}
														</Checkbox>
													))}
											</CheckboxGroup>
										) : (
											null!
										)}
									</CheckboxGroup>
									{agenciesError.length > 0 && (
										<HelpText variant="error">
											{agenciesError}
										</HelpText>
									)}
								</Box>


							</Flex>
						</Box>

						<Separator orientation="horizontal" verticalSpacing="space50" />

						<Box paddingRight="space60">
							<SubtitleText subtitleText="Branding Configuration" />
							{/* <Flex>

								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="title">Title</Label>
									<Input
										id="onScreenTitle"
										name="onScreenTitle"
										type="text"
										onChange={handleChange}
										value={videoCampaign.onScreenTitle}

									></Input>
								</Box>

								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="onScreenDescription">Description</Label>
									<TextArea
										onChange={(e) => {
											handleTextChange(e);
										}}
										placeholder="Add text"
										id="onScreenDescription"
										name="onScreenDescription"

										value={videoCampaign.onScreenDescription}
									/>
								</Box>

									</Flex>*/}

							<Flex>

								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="backgroundColor">Background Color</Label>
									<input
										id="backgroundColor"
										name="backgroundColor"
										type="color"
										className="colorPicker"
										placeholder={videoCampaign.backgroundColor || "#0085AD"}
										onChange={handleChange}
										value={videoCampaign.backgroundColor || "#0085AD"}
									></input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="buttonColor">Button Color</Label>
									<input
										id="buttonColor"
										name="buttonColor"
										type="color"
										className="colorPicker"
										placeholder={videoCampaign.buttonColor || "#032E4D"}
										onChange={handleChange}
										value={videoCampaign.buttonColor || "#032E4D"}
									></input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="textColor">Text Color</Label>
									<input
										id="textColor"
										name="textColor"
										type="color"
										className="colorPicker"
										placeholder={videoCampaign.textColor || "#FFFFFF"}
										onChange={handleChange}
										value={videoCampaign.textColor || "#FFFFFF"}
									></input>
								</Box>
								<Box width="50%" paddingBottom="space60" paddingRight="space60">
									<Label required htmlFor="linkColor">Link Color</Label>
									<input
										id="linkColor"
										name="linkColor"
										type="color"
										className="colorPicker"
										placeholder={videoCampaign.linkColor || "#64D3E4"}
										onChange={handleChange}
										value={videoCampaign.linkColor || "#64D3E4"}
									></input>
								</Box>

							</Flex>

							<Box width="50%" paddingBottom="space60" paddingRight="space60">
								<Label required htmlFor="logoUrl">Logo URL</Label>
								<Input
									id="logoUrl"
									name="logoUrl"
									type="text"
									onChange={handleChange}
									value={videoCampaign.logoUrl || "https://cdn.ciptex.com/nfcc/nfcc-white.png"}
									// defaultValue={agencyVideo.accountSid}
									required
								></Input>
							</Box>

						</Box>

						<Box marginTop="space60">

							<Stack orientation="horizontal" spacing="space200">
								<Button variant="secondary" onClick={() => navigate(-1)}>
									Cancel
								</Button>
								<Button type="submit" variant="primary" onClick={handleSubmit}>
									Save
								</Button>
							</Stack>

						</Box>

					</>
				) : (
					<Flex vAlignContent="center" hAlignContent="center">
						<Spinner size="sizeIcon110" decorative={false} title="Loading" />
					</Flex>
				)}
			</Box>
		</Box>
	);
};
