import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNFCCContext } from "../../hooks/useNFCCContext/useNFCCContext";
import { Services, Agencies, VideoCampaigns } from "@ciptex/nfcc";
import { Box } from "@twilio-paste/core/box";
import { Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core/table";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { Button, AlertDialog, Flex } from "@twilio-paste/core";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { HeaderTitleText } from "../HeaderTitleText/HeaderTitleText";
import { DateTime } from "luxon";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useToasterContext } from "../../hooks/useToasterContext";
import { useNavigate, useLocation } from "react-router-dom";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Label } from "@twilio-paste/core/label";
import { Select, Option } from "@twilio-paste/core/select";
import { FilterIcon } from "@twilio-paste/icons/esm/FilterIcon";
import { Stack } from "@twilio-paste/core/stack";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";

export const VideoCampaignsTable: FC = () => {
	const location = useLocation();
	const [videoCampaigns, setVideoCampaigns] = useState<any[]>();
	const [filteredVideoCampaigns, setFilteredVideoCampaigns] = useState<any[]>();
	const [services, setServices] = useState<Services>();
	const [agencies, setAgencies] = useState<Agencies>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [serviceFilterValue, setServiceFilterValue] = useState<string>("All Services");
	const [languageFilterValue, setLanguageFilterValue] = useState<string>("All Languages");
	const [agencyFilterValue, setAgencyFilterValue] = useState<string>("All Agencies");


	const [isBooths, setIsBooths] = useState<boolean>(false);

	const { listServices, listAgencies, listVideoCampaigns, deleteVideoCampaign } = useNFCCContext();
	const { appState } = useAppState();
	const { toaster } = useToasterContext();

	// confirmation modal state management
	const [toDelete, setToDelete] = useState<any>();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = (videoCampaignId: number, rowIndex: number) => {
		setToDelete({ videoCampaignId, rowIndex });
		setIsOpen(true);
	};
	const handleClose = () => {
		setIsOpen(false);
	};

	const navigate = useNavigate();

	const getDate = () => {
		const today = DateTime.local();
		const todayDate = today.toFormat("dd-MM-yyyy-HH-mm-ss");
		return todayDate;
	};

	const exportTableData = () => {
		if (filteredVideoCampaigns) {
			const csvData = [
				[
					"Name",
					"URL",
					"Location",
					"Code Enabled",
					"Services",
					"Languages",
					"Agencies"
				],
				...filteredVideoCampaigns.map(
					({
						name,
						url,
						location,
						isCodeEnabled,
						servString,
						langString,
						agString
					}) => [
						name,
						url,
						location,
						isCodeEnabled,
						servString.replaceAll(",", ";"),
						langString.replaceAll(",", ";"),
						agString.replaceAll(",", ";")
					]
				)
			];
			const csv = csvData.map((row) => row.join(",")).join("\n");
			const csvBlob = new Blob([csv], { type: "text/csv" });
			const csvUrl = URL.createObjectURL(csvBlob);
			const downloadLink = document.createElement("a");
			downloadLink.href = csvUrl;
			if (isBooths) {
				downloadLink.download = `booths-${getDate()}.csv`;
			}
			else {
				downloadLink.download = `video-campaigns-${getDate()}.csv`;
			}

			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	};

	useEffect(() => {
		(async () => {
			try {


				let isBooth = false;
				console.log(location.pathname)
				if (location.pathname.includes('booth')) {
					console.log('here')
					setIsBooths(true);
					isBooth = true;
				}
				const data: VideoCampaigns = await listVideoCampaigns();
				if (data) {
					const newVCs = [];
					let filtered: VideoCampaigns = [];

					if (isBooth)
						filtered = data.filter(
							(a) => (a.type ?? "") == "booth"
						)
					else {
						filtered = data.filter(
							(a) => (a.type ?? "") == "web"
						)
					}

					for (const d of filtered) {
						let l = "";
						let s = "";
						let a = "";

						for (const la of d.availableLanguages ?? []) {
							l += la + ", ";
						}

						for (const la of d.availableServices ?? []) {
							s += la + ", ";
						}


						for (const la of d.availableAgencies ?? []) {
							a += la.name + ", ";
						}

						l = l.slice(0, -2);
						s = s.slice(0, -2);
						a = a.slice(0, -2);

						newVCs.push({ ...d, ["langString"]: l, ["servString"]: s, ["agString"]: a });
					}

					setVideoCampaigns(newVCs);
					setFilteredVideoCampaigns(newVCs)

					const s = await listServices();
					setServices(s);

					const a = await listAgencies();
					setAgencies(a);

					setLoaded(true);
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "Could not retrieve video campaign data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const handleDelete = async (videoCampaignId: number, rowIndex: number) => {
		if (filteredVideoCampaigns) {
			try {
				await deleteVideoCampaign(videoCampaignId);

				let msg = ' deleted successfully'

				if (isBooths) {
					msg = 'Booth' + msg
				}
				else {
					msg = 'Video campaign' + msg
				}

				toaster.push({
					message: msg,
					variant: "success",
					dismissAfter: 4000
				});

				setVideoCampaigns(
					(videoCampaigns ?? [{}]).filter((v) => v.videoCampaignId !== videoCampaignId)
				);

				setFilteredVideoCampaigns(
					(filteredVideoCampaigns ?? [{}]).filter((v) => v.videoCampaignId !== videoCampaignId)
				);
			} catch (error) {
				console.error(error);
				let msg = ' could not be deleted'

				if (isBooths) {
					msg = 'Booth' + msg
				}
				else {
					msg = 'Video campaign' + msg
				}
				toaster.push({
					message: msg,
					variant: "error",
					dismissAfter: 4000
				});
			}
		}
	};


	const handleCloseWithDelete = () => {
		setIsOpen(false);
		handleDelete(toDelete.videoCampaignId, toDelete.rowIndex);
		setToDelete({});
	};



	const handleFilterChange = ({
		target
	}: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (target.name === "service") {
			setServiceFilterValue(target.value)
		}
		else if (target.name === "language") {
			setLanguageFilterValue(target.value)
		}
		else if (target.name === "agency") {
			setAgencyFilterValue(target.value)
		}

	};


	const applyFilters = () => {
		if (videoCampaigns) {

			let filtered: any[] = videoCampaigns;

			if (serviceFilterValue !== "All Services") {
				filtered = filtered.filter(
					(a) => (a.servString ?? "").includes(serviceFilterValue)
				)
			}

			if (languageFilterValue !== "All Languages") {
				filtered = filtered.filter(
					(a) => (a.langString ?? "").includes(languageFilterValue)
				)
			}

			if (agencyFilterValue !== "All Agencies") {
				filtered = filtered.filter(
					(a) => (a.agString ?? "").includes(agencyFilterValue)
				)
			}

			setFilteredVideoCampaigns(filtered)

		}

	};

	return (
		<Box width="100%">
			<HeaderTitleText titleText={isBooths && "Booths" || "Web Video Campaigns"} />
			<Box display="flex" justifyContent="flex-end" marginY="space60">
				<Flex>
					<Box display="flex" justifyContent="space-between" marginY="space60" alignItems="flex-end" columnGap="space60">
						<Button variant="primary" onClick={() => navigate("add")}>
							<PlusIcon decorative={false} title="Add new service" />
							{isBooths && "Add New Booth" || "Add New Video Campaign"}
						</Button>

						<Button variant="secondary" onClick={exportTableData}>
							Download CSV
							<DownloadIcon decorative={false} title="Download csv icon" />
						</Button>
					</Box>
				</Flex>
			</Box>

			<Box display="flex" flexDirection="row" marginY="space60" justifyContent="space-between" alignItems="flex-end">

				<Box display="flex" flexDirection="row" columnGap="space60">



					<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
						<Label htmlFor="service">Service</Label>
						<Select id="service" name="service" onChange={handleFilterChange} value={serviceFilterValue}>
							<Option value="All Services">All Services</Option>
							{services && loaded ? (

								services && services.map((s: any, index: number) => (

									<Option key={index} value={s.name} >{s.name}</Option>

								))) : null!}
						</Select>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
						<Label htmlFor="language">Language</Label>
						<Select id="language" name="language" onChange={handleFilterChange} value={languageFilterValue}>
							<Option value="All Languages">All Languages</Option>
							<Option value="English">English</Option>
							<Option value="Spanish">Spanish</Option>
						</Select>
					</Box>

					<Box display="flex" flexDirection="column" width="15vw" alignItems="flex-start">
						<Label htmlFor="agency">Agency</Label>
						<Select id="agency" name="agency" onChange={handleFilterChange} value={agencyFilterValue}>
							<Option value="All Agencies">All Agencies</Option>
							{agencies && loaded ? (

								agencies && agencies.map((a: any, index: number) => (

									<Option key={index} value={a.name} >{a.name}</Option>

								))) : null!}
						</Select>
					</Box>

				</Box>

				<Box display="flex" justifyContent="flex-end" height="fit-content">
					<Button variant="primary" onClick={applyFilters}>
						<FilterIcon decorative={false} title="Apply filters" />
							Apply
					</Button>
				</Box>

			</Box>

			<Table aria-label="Video campaigns table" striped>
				<THead>
					<Tr>
						<Th>Actions</Th>
						<Th>Name</Th>
						<Th>URL</Th>
						{isBooths && <Th>Location</Th>}
						{/* {isBooths && <Th>State</Th>} */}
						{/* {isBooths && <Th>Location Contact</Th>} */}
						{isBooths && <Th>Code Enabled?</Th>}
						<Th width="300px">Services</Th>
						<Th>Languages</Th>
						<Th width="300px">Agencies</Th>
					</Tr>
				</THead>
				<TBody>
					{filteredVideoCampaigns && loaded ? (
						filteredVideoCampaigns &&
						filteredVideoCampaigns.map((vc: any, rowIndex: number) => (
							<Tr key={rowIndex}>
								<Td>
									<Box
										display="flex"
										justifyContent="flex-start"
										flexDirection="row"
										columnGap="space60"
									>
										<Stack orientation="vertical" spacing="space60">
											<Button
												variant="secondary"
												onClick={() =>
													navigate(location.pathname + `/edit/${vc.videoCampaignId}`)
												}
											>
												<EditIcon decorative={false} title="Edit" />
											</Button>
											<Button
												variant="destructive"
												onClick={() => handleOpen(vc.videoCampaignId, rowIndex)}
											>
												<DeleteIcon
													decorative={false}
													title="Delete"
												/>
											</Button>
										</Stack>
									</Box>
								</Td>
								<Td>{vc.name}</Td>
								<Td>{vc.url}</Td>

								{isBooths && <><Td>
									{vc.location + " (" + vc.state + ")"}
								</Td>
								{/* <Td>{vc.state}</Td> */}
								{/* <Td>{vc.locationContactName}</Td> */}
								<Td>{vc.isCodeEnabled == 0 && "No" || "Yes"}</Td>
								</>}
								<Td>{vc.servString}</Td>
								<Td>{vc.langString}</Td>
								<Td>{vc.agString}</Td>

							</Tr>
						))
					) : (
						<TableSkeletonLoader numberOfTd={isBooths && 8 || 6} numberOfTr={1} />
					)}
				</TBody>
			</Table>
			<AlertDialog
				heading={isBooths && "Deactivate Booth" || "Deactivate Video Campaign"}
				isOpen={isOpen}
				destructive
				onConfirm={handleCloseWithDelete}
				onConfirmLabel="Delete"
				onDismiss={handleClose}
				onDismissLabel="Cancel"
			>
				{isBooths && "Please confirm you want to delete this booth." || "Please confirm you want to delete this video campaign."}

			</AlertDialog>
		</Box>
	);
};
